import React from 'react';
import ProductPageTemplate from '../../components/ProductPageTemplate';

import breakout_00 from '../../images/breakout_00_1600.webp'
import breakout_01 from '../../images/breakout_01_1600.webp'
import breakout_02 from '../../images/breakout_02_1600.webp'
import breakout_03 from '../../images/breakout_03_1600.webp'
import breakout_04 from '../../images/breakout_04_1600.webp'
import breakout_thumb_00 from '../../images/breakout_00_400.webp'
import breakout_thumb_01 from '../../images/breakout_01_400.webp'
import breakout_thumb_02 from '../../images/breakout_02_400.webp'
import breakout_thumb_03 from '../../images/breakout_03_400.webp'
import breakout_thumb_04 from '../../images/breakout_04_400.webp'

const context = {
  title: 'Break-Out Automatik&#173;schiebetür',
  description: '24/7 im Flucht- und Rettungsweg zugelassen und trotzdem verriegelt',
  text: 'Eine automatische Schiebetür mit Break-Out besteht, genau wie die Automatikschiebetür, aus ein bis zwei sich öffnenden Türflügeln sowie einer Antriebs- und Sensorikeinheit. Sie kann beispielsweise in ein Rahmenelement (Festverglasung) oder in ein Mauerwerk eingefasst werden. Die Einbaumaße dieser Türe bewegen sich meist in einer lichten Einbaubreite von 900 mm bis 2600 mm. \n\n' +

  'Die Automatikschiebetür mit Break-Out-Funktion ist, im Gegensatz zur standartmäßigen Variante, auch im verriegelten Zustand im Flucht- und Rettungsweg zugelassen. Durch den Einsatz eines leicht zu bedienenden Fluchttürterminals und dem nach außen öffnenden Drehbeschlag, ist die Türe im Ernstfall weiterhin begehbar. \n\n' +

  'Nicht von der Stange -  jede Automatiktür ist eine individuell angepasste Lösung. Denn erst wenn das Produkt und die örtlichen Gegebenheiten ineinandergreifen kann eine langfristig zufriedenstellende Eingangssituation entstehen. Um diese nach Ihren Vorstellungen und Wünschen technisch und optisch perfekt zu gestalten, legen wir von Automatiktür24 größten Wert auf eine smarte Beratung und kompetente Umsetzung.',
  features: [
    //'autschr',
    'barrierefreiheit',
    //'brandschutz',
    'breakOut',
    //'design',
    //'din18650',
    //'energieeinsparung',
    //'modernisierung',
    'notStrom',
    'personenfrequenz',
    //'platzsparend',
    //'transparenz',
    //'einbruchhemmung',
    'zutrittskontrolle',
    'fluchtweg',
  ],
  legalRequirements: [
    'autschr',
    'din18650',
    //'dinEn170',
    'eltvtr',
  ],
  carouselImages: [
    {
      original: breakout_00,
      thumbnail: breakout_thumb_00,
      originalAlt: 'Break-Out Automatikschiebetür'
    },
    {
      original: breakout_01,
      thumbnail: breakout_thumb_01,
      originalAlt: 'Break-Out Automatikschiebetür'
    },
    {
      original: breakout_02,
      thumbnail: breakout_thumb_02,
      originalAlt: 'Break-Out Automatikschiebetür'
    },
    {
      original: breakout_03,
      thumbnail: breakout_thumb_03,
      originalAlt: 'Break-Out Automatikschiebetür'
    },
    {
      original: breakout_04,
      thumbnail: breakout_thumb_04,
      originalAlt: 'Break-Out Automatikschiebetür'
    },
  ],
  configurator: true,
}

const Page = () => {

  return(
    <ProductPageTemplate pageContext={context} />
  )
}

export default Page;
